import React, {useEffect, useState} from "react";

export const TitleContext = React.createContext();

export const TitleProvider = ({ children }) => {
    
    const [title, setTitle] = useState("")
    const [subtitle, setSubtitle] = useState("")


    return (
        <TitleContext.Provider
          value={{title, setTitle, subtitle, setSubtitle}}
        >
            {children}
        </TitleContext.Provider>
    )
}