import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    Checkbox,
    Stack,
    Link,
    Button,
    Heading,
    Text,
    useColorModeValue,
    Tooltip,
    Modal,
    ModalOverlay,
    useDisclosure,
    ModalContent,
    ModalBody,
    ModalHeader,
    ModalCloseButton
} from '@chakra-ui/react';
import { useState } from 'react';
import React from 'react';
import { 
    InputGroup,
    InputRightElement
} from '@chakra-ui/react';
import { QuestionOutlineIcon, ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { auth } from '../../utils/Authentication/firebase';
import { signInWithEmailAndPassword, setPersistence, browserLocalPersistence, inMemoryPersistence } from 'firebase/auth';
import { useLocation, useNavigate } from 'react-router-dom';
import DividerWithText from './DividerWithText';
import ExternalAuth from "./ExternalAuth";
import { toast } from 'react-hot-toast';
import { getMessageFromErrorCode } from '../../utils/Authentication/firebase';
import ForgotPasswordForm from './ForgotPasswordModal';

  
  export default function LoginCard() {
    const [showPassword, setShowPassword] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [rememberMe, setRememberMe] = useState(false);
    const navigate = useNavigate();
    const { state }= useLocation();
    const { isOpen, onOpen, onClose } = useDisclosure()

    const signIn = (e) => {
        e.preventDefault();
        signInWithEmailAndPassword(auth, email, password)
          .then((userCredential) => {
              console.log(userCredential);
              state ? navigate(state.from) : navigate("/home")
          })
          .catch((error) => {
            console.log(error.code)
            toast.error(getMessageFromErrorCode(error))
          });
    }

    return (
      <Flex
        align={'center'}
        justify={'center'}>
        <Stack spacing={8} mx={["none",'auto']} maxW={'90vw'} py={12} px={0} w={["90vw", "90vw", "40vw","40vw"]}>
          <Stack align={'center'}>
            <Heading fontSize={'4xl'}>Login</Heading>
            <Text fontSize={["md",'lg']} color={'gray.600'} align="center">
              to enjoy all of our magical AI tools ✨
            </Text>
          </Stack>
          <form onSubmit={signIn}>
          <Box
            rounded={'lg'}
            bg={useColorModeValue('white', 'gray.700')}
            boxShadow={'lg'}
            p={8}>
            <Stack spacing={4}>
              <FormControl id="email" isRequired>
                <FormLabel>Email address</FormLabel>
                <Input type="email" value={email} onChange={(event)=>{setEmail(event.target.value)}} focusBorderColor="primary.200"/>
              </FormControl>
              <FormControl id="password" isRequired>
                <FormLabel>Password</FormLabel>
                <InputGroup>
                  <Input type={showPassword ? 'text' : 'password'} value={password} onChange={(event)=>{setPassword(event.target.value)}} focusBorderColor="primary.200"/>
                  <InputRightElement h={'full'}>
                    <Button
                      variant={'ghost'}
                      onClick={() =>
                        setShowPassword((showPassword) => !showPassword)
                      }
                      _hover={{
                        bg: "primary.100"
                      }}>
                      {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              <Stack spacing={10}>
                <Stack
                  direction={"row"}
                  align={'center'}
                  justify={'start'}>
                    
                      <span>
                  <Checkbox isChecked={rememberMe} onChange={(e)=>{setRememberMe(e.target.checked)}} >Remember me</Checkbox>
                  </span>
                  <Tooltip label={"Keep this checked for quicker access on this computer."}>
                    <QuestionOutlineIcon m={0} p={0} alignSelf="center" ></QuestionOutlineIcon>
                  </Tooltip>
                  

                </Stack>
                <Button
                  type='submit'
                  bg={'primary.400'}
                  color={'white'}
                  _hover={{
                    bg: 'primary.500',
                  }}>
                  Login
                </Button>
              </Stack>
              <Stack direction={"row"} justify={"space-between"}>
              <Link color={'primary.400'} onClick={onOpen}>Forgot password?</Link>
              <Link color={'primary.400'} href="/signup">Register</Link>
              
              </Stack>

              <DividerWithText>OR</DividerWithText>

              <ExternalAuth />
            </Stack>
          </Box>
          </form>
        </Stack>

        <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay/>
        <ForgotPasswordForm onClose={onClose}></ForgotPasswordForm>
          
        </Modal>
      </Flex>
    );
  }