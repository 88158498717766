import { CopyIcon } from "@chakra-ui/icons";
import { 
    Card, Input, InputGroup, InputRightElement, Text, Stack, Button, Accordion, AccordionItem, AccordionButton, Box, AccordionIcon, AccordionPanel
} from "@chakra-ui/react";
import React from "react";
import toast from "react-hot-toast";
import TextareaWithIcon from "../TextareaWithIcon";


const DESCRIPTION_CHAR_LIMIT = 2000;

function DescriptionBox({description, setDescription}){

    function copyContent(text, type){
        if(text.trim() === ""){
            toast.error(type+" is empty");
            return
        }
        navigator.clipboard.writeText(text);
        toast.success(type+" has been copied!");
    }
    
    return (
        <Accordion defaultIndex={[0]} allowMultiple>
        <AccordionItem border={0}>
        <Card p={3} h={"auto"}>
        <AccordionButton px={1}>
            <Box as="span" flex='1' textAlign={"left"}><Text color={"primary.700"} fontWeight={"semibold"} fontSize={["md","md","xl"]}>Description</Text></Box><AccordionIcon/>
            </AccordionButton>
            <AccordionPanel px={1}>
            <TextareaWithIcon width={"100%"} icon={CopyIcon} value={description} onChange={(event)=>{setDescription(event.target.value)}} countermax={DESCRIPTION_CHAR_LIMIT} countervalue={description.length ? description.length : 0} copyType={"Description"}></TextareaWithIcon>
            </AccordionPanel>
        </Card>
        </AccordionItem>
        </Accordion>
    )
}

export default DescriptionBox;