import { React, useState } from "react";
import {
    Stack,
    Text,
    Button,
    InputGroup,
    Input,
    InputRightElement,
    Tooltip,
    AccordionItem,
    AccordionButton,
    AccordionIcon,
    AccordionPanel,
    Box
} from "@chakra-ui/react";
import toast from "react-hot-toast";
import { CopyIcon } from "@chakra-ui/icons";
import TextareaWithIcon from "../TextareaWithIcon";

const BULLET_CHAR_LIMIT = 200;

function Bulletpoint({title, bulletpoint, setBullets, index}){

    function copyContent(text, type){
        if(text.trim() === ""){
            toast.error(type+" is empty");
            return
        }
        navigator.clipboard.writeText(text);
        toast.success(type+" has been copied!");
    }

    return(
        <AccordionItem>
        
        <h2><AccordionButton px={1}><Box as="span" flex='1' textAlign={"left"}><Text color={"primary.700"} fontWeight={"semibold"} fontSize={["sm","sm","md"]}>{title}</Text></Box><AccordionIcon/></AccordionButton></h2>
            
            <AccordionPanel pb={4} px={1}>
                <TextareaWithIcon width={"100%"} icon={CopyIcon} value={bulletpoint} onChange={(event)=>{setBullets(index, event.target.value)}} countermax={BULLET_CHAR_LIMIT} countervalue={bulletpoint.length ? bulletpoint.length : 0} copyType={"Bulletpoint "+(index+1)}></TextareaWithIcon>
            </AccordionPanel>
        </AccordionItem>
    )
}

export default Bulletpoint;