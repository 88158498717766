import React, { useContext, useState } from "react";
import { 
  Box,
  Flex,
  Text,
  Button,
  Stack,
  useDisclosure,
  Heading,
  MenuList,
  Avatar,
  MenuButton,
  Menu,
  Icon,
  Modal,
  ModalOverlay
} from "@chakra-ui/react";
import Logo from "../media/logo_color_transparent.png";
import { Link } from "react-router-dom";
import { Img } from "@chakra-ui/react";
import { AuthContext } from '../utils/Authentication/AuthContext';
import { signOut } from "firebase/auth";
import { auth } from "../utils/Authentication/firebase";
import { TitleContext } from "../utils/GlobalTitleContext";
import { FaSignOutAlt } from "react-icons/fa"
import SettingsModal from "./SettingsModal"

const Navbar = (props) => {
  const { isOpen, onToggle } = useDisclosure()
  const {title, subtitle} = useContext(TitleContext);

  return (
    <NavBarContainer {...props}>
      <Link to="/"><Img src={Logo} w={["5vh","5vh","8vh","8vh"]}></Img></Link>
      <Stack direction={"row"} maxWidth={["70vw"]}>
        <Heading align={"center"} fontSize={["xl","xl","3xl"]} fontWeight="bold">{title} 
          <Text fontSize={["xs","xs","md"]} fontWeight="medium">{subtitle}</Text>
        </Heading>
      </Stack>
      <MenuToggle toggle={onToggle} isOpen={isOpen} />
      <MenuLinks isOpen={isOpen} toggle={onToggle}/>
    </NavBarContainer>
  );
};

const CloseIcon = () => (
  <svg width="24" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <title>Close</title>
    <path
      color="primary.700"
      d="M9.00023 7.58599L13.9502 2.63599L15.3642 4.04999L10.4142 8.99999L15.3642 13.95L13.9502 15.364L9.00023 10.414L4.05023 15.364L2.63623 13.95L7.58623 8.99999L2.63623 4.04999L4.05023 2.63599L9.00023 7.58599Z"
    />
  </svg>
);

const MenuIcon = () => (
  <svg
    width="24px"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    fill="primary.700"
  >
    <title>Menu</title>
    <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
  </svg>
);

const MenuToggle = ({ toggle, isOpen }) => {
  return (
    <Box display={{ base: "block", md: "none" }} onClick={toggle}>
      {isOpen ? <CloseIcon /> : <MenuIcon />}
    </Box>
  );
};

const MenuItem = ({ children, isLast, linkTo = "/", toggle, ...rest }) => {
  return (
    <Link to={linkTo} >
      <Text display="block" fontWeight={"semibold"} color={"primary.700"} {...rest} >
        {children}
      </Text>
    </Link>
  );
};

const MenuLinks = ({ isOpen, toggle }) => {
  const {currentUser} = useContext(AuthContext);
  return (
    
    <Box
      display={{ base: isOpen ? "block" : "none", md: "block" }}
      flexBasis={{ base: "100%", md: "auto" }}
      mt={2}
    >
      <Stack
        spacing={7}
        align="center"
        justify={["center", "space-between", "flex-start", "flex-start"]}
        direction={["column", "column", "row", "row"]}
        pt={[4, 4, 0, 0]}
      >
        <MenuItem onClick={toggle}>Home</MenuItem>

        <MenuItem linkTo="/listing-ai" onClick={toggle}> ShopWrite<sup>AI</sup> </MenuItem>

        <MenuItem linkTo="/listing-builder" onClick={toggle}> ListingBuilder </MenuItem>

        {/* <MenuItem linkTo="/listing-upgrader" onClick={toggle}> ListingUpgrader </MenuItem> */}

        {!currentUser && <MenuItem linkTo="/login" onClick={toggle}> Sign In </MenuItem>}
        
        {!currentUser && <MenuItem linkTo="/signup" onClick={toggle} isLast>
          <Button
            size="sm"
            rounded="md"
            color={"white"}
            bg={"primary.700"}
            _hover={{
              bg: ["primary.100", "primary.100", "primary.600", "primary.600"]
            }}
          >
           Get started
          </Button>
        </MenuItem>}

        {currentUser && <UserMenu></UserMenu>}
        </Stack>
    </Box>
  );
};

const NavBarContainer = ({ children, ...props }) => {
  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      w="100%"
      mb={2}
      p={4}
      bg={["primary.500", "primary.500", "transparent", "transparent"]}
      color={["white", "white", "primary.700", "primary.700"]}
      {...props}
    >
      {children}
    </Flex>
  );
};

export function modalClose(onCloseParam, settingsChange, setSettingsChange){
  if(settingsChange){
    if(window.confirm("There are unsaved changes. Are you sure you want to close the settings?")){
      setSettingsChange(false)
      onCloseParam()
    }
  } else {
    onCloseParam()
  }
}

const UserMenu = () => {
  const {currentUser} = useContext(AuthContext);
  const { isOpen: modalIsOpen, onOpen, onClose } = useDisclosure()

  const [settingsChange, setSettingsChange] = useState(false);

  console.log(currentUser)
  return (
    <Menu>
      <MenuButton
        as={Button}
        rounded={'full'}
        variant={'link'}
        cursor={'pointer'}
        minW={0}>
        <Avatar
          background={"primary.700"}
          size={'sm'}
          name={currentUser.email}
          src={
            ''
          }
        />
      </MenuButton>
      <MenuList m={2} p={3}>
        {/* <MenuItem>Account</MenuItem> */}
        {/* <Text onClick={onOpen} display="block" fontWeight={"semibold"} color={"primary.700"} cursor={"pointer"}>Edit OpenAI API key</Text> */}
        <MenuItem onClick={()=>{signOut(auth).then(()=>{console.log("Signed out successfully!")})}}>
        <Stack direction={"row"} align={"center"}>
        <Text>Sign Out</Text>
        <Icon as={FaSignOutAlt}></Icon>
        </Stack></MenuItem>
      </MenuList>

      <Modal isOpen={modalIsOpen} onClose={()=>{modalClose(onClose, settingsChange, setSettingsChange)}}>
        <ModalOverlay settingschange={settingsChange} setsettingschange={setSettingsChange}/>
        <SettingsModal/>
      </Modal>

    </Menu>
  )
}

export default Navbar;
