import React from 'react';
import { Outlet, Navigate } from 'react-router-dom'
import { AuthContext } from '../utils/Authentication/AuthContext';
import { useContext } from 'react';


const PrivateRoutes = () => {
    const {currentUser} = useContext(AuthContext);
    return(
        currentUser ? currentUser.emailVerified ? <Outlet/> : <Navigate to="/verify-email"/> : <Navigate to="login"/>
    )
}

export default PrivateRoutes;
