import {React, useEffect, useState} from "react";
import { 
  Stack,
  Heading,
  Tag,
  Text,
  Button
} from "@chakra-ui/react";
import KeywordData from "../components/ListingBuilder/KeywordData"
import TitleBox from "../components/ListingBuilder/TitleBox";
import BulletsBox from "../components/ListingBuilder/BulletpointsBox";
import DescriptionBox from "../components/ListingBuilder/DescriptionBox";
import { AuthContext } from '../utils/Authentication/AuthContext';
import { useContext } from 'react';
import { doc, serverTimestamp, getDoc, setDoc, deleteDoc } from "firebase/firestore";
import { db } from "../utils/Authentication/firebase";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { UilSave } from '@iconscout/react-unicons'
import { UilTrashAlt as DeleteIcon} from '@iconscout/react-unicons'
import { TitleContext } from "../utils/GlobalTitleContext";



function ListingBuilder(props){
    const {setTitle :setPageTitle, setSubtitle} = useContext(TitleContext)
    setPageTitle("Listing Builder")
    setSubtitle("Build your E-Commerce listing based on keywords, SEO and norms.")
    const {currentUser} = useContext(AuthContext);
    const navigate = useNavigate();

    const [inputs, setInputs] = useState({
        title: "",
        bullets: {
            bullet1: "",
            bullet2: "",
            bullet3: "",
            bullet4: "",
            bullet5: ""
        },
        description: ""
    });

    const [keywords, setKeywords] = useState([]);
    const [allEntries, setAllEntries] = useState("");

    const setBullets = (bulletIndex, newBullet) => {
        switch(bulletIndex){
            case 0: setInputs({...inputs, bullets: {...inputs.bullets, bullet1: newBullet}});break;
            case 1: setInputs({...inputs, bullets: {...inputs.bullets, bullet2: newBullet}});break;
            case 2: setInputs({...inputs, bullets: {...inputs.bullets, bullet3: newBullet}});break;
            case 3: setInputs({...inputs, bullets: {...inputs.bullets, bullet4: newBullet}});break;
            case 4: setInputs({...inputs, bullets: {...inputs.bullets, bullet5: newBullet}});break;
            default: console.log("Error while setting Bulletpoints object.");
        }
    }

    const setTitle = (newTitle) => {
        setInputs({...inputs, title: newTitle})
    }

    const setDescription = (newDescription) => {
        setInputs({...inputs, description: newDescription})
    }

    

    useEffect(() => {
        setAllEntries(inputs.title.trim().concat(" ", inputs.bullets.bullet1.trim()).concat(" ", inputs.bullets.bullet2.trim()).concat(" ", inputs.bullets.bullet3.trim()).concat(" ", inputs.bullets.bullet4.trim()).concat(" ", inputs.bullets.bullet5.trim()).concat(" ", inputs.description.trim()));
    },[inputs]);


    const params = useParams();
    const listingId = params.id
    
    const uploadListingToDb = () => {

        toast.promise(
            setDoc(doc(db, "users", currentUser.uid, "listings", listingId), {
            keywords: keywords,
            listing: inputs,
            timestamp: serverTimestamp()
        }),
        {
            loading: "Saving listing...", 
            success: "Sucessfully saved listing", 
            error: (err) => `Something went wrong: ${err.toString()}`
        })

    }

    const deleteListing = async () => {
        if(window.confirm(`Are you sure you want to delete "${inputs.title ? inputs.title.length > 20 ? inputs.title.slice(0, 20) + "..." : inputs.title : listingId}"?`)){
            await deleteDoc(doc(db, "users", currentUser.uid, "listings", listingId))
            navigate("/listing-builder")
            toast.success(`Listing "${inputs.title ? inputs.title.length > 20 ? inputs.title.slice(0, 20) + "..." : inputs.title : listingId}" deleted`)
        }   
    }

    const importListingFromDb = async () => {
        const document = await getDoc(doc(db, 'users', currentUser.uid, 'listings', listingId))
        if(document.exists()){
            setInputs({
                title: document.data().listing.title,
                description: document.data().listing.description,
                bullets: document.data().listing.bullets
            })
            setKeywords(document.data().keywords)
        }
        
        return Promise.resolve(true)
    }

    useEffect(() => {
        importListingFromDb();
    }, [])

    return(
        <Stack direction={["column","column","column"]} m={2} spacing={2} maxW={"95vw"} p={2}>
            <Stack direction={"column"} align={"center"}>
                <Stack direction={"row"} w={["100%","100%","100%"]} justify={["space-between","space-between","right"]}>
                    <Button leftIcon={<UilSave></UilSave>} onClick={uploadListingToDb} variant='outline' colorScheme={"primary"}>Save Listing</Button>
                    <Button leftIcon={<DeleteIcon></DeleteIcon>} onClick={deleteListing} variant='outline' colorScheme={"red"}>Delete Listing</Button>
                </Stack>
            
            </Stack>
            <Stack direction={["column","column","row"]} h={"auto"} spacing={2} align={["center","center","start"]}>
                <KeywordData keywords={keywords} setKeywords={setKeywords} allEntries={allEntries}></KeywordData>
                <Stack direction={"column"} w={["105%","105%","50vw"]} spacing={3}>
                    <TitleBox title={inputs.title} setTitle={setTitle}> </TitleBox>
                    <BulletsBox bullets={inputs.bullets} setBullets={setBullets}> </BulletsBox>
                    <DescriptionBox description={inputs.description} setDescription={setDescription}></DescriptionBox>
                </Stack>
                
            </Stack>
            
            
        </Stack>
    )
}

export default ListingBuilder;