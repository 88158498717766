import { Box, Textarea, IconButton, Text } from "@chakra-ui/react";
import React from "react";
import { toast } from "react-hot-toast";
import ResizeTextarea from "react-textarea-autosize";


const TextareaWithIcon = React.forwardRef((props, ref) => {

    function copyContent(value, copyType){
        navigator.clipboard.writeText(value);
        toast.success(`${copyType ? copyType : "Text"} has been copied!`, {
          position: "bottom-center"
        });
      }

    const PropIcon = props.icon;
    return (
        <Box position={"relative"}>
        <IconButton size="md" variant={"ghost"} position={"absolute"} top={2} right={2} zIndex={5} onClick={()=>{copyContent(props.value, props.copyType)}} colorScheme={"primary"}><PropIcon></PropIcon></IconButton>
        
        {props.countermax && <Text color={props.countervalue < props.countermax ? "primary.700" : "red"} fontSize={"xs"} position={"absolute"} bottom={2} right={2} zIndex={5}>{props.countervalue}/{props.countermax}</Text>}
        <Textarea 
            w={"inherit"} 
            h={"inherit"} 
            pr={"4em"}
            minH="unset"
            overflow="hidden"
            resize="none"
            ref={ref}
            minRows={3}
            fontSize={"sm"}
            as={ResizeTextarea}
            {...props}
        >
        </Textarea>
        </Box>
    )
})

export default TextareaWithIcon;