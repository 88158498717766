import {React, useState} from "react";
import toast from 'react-hot-toast';
import {
  Stack,
  FormControl,
  FormLabel,
  Input,
  Button,
  Icon,
  Box,
  Collapse,
  SlideFade,
  Select,
  Card,
  useDisclosure,
  Skeleton
} from "@chakra-ui/react";
import {ArrowForwardIcon} from "@chakra-ui/icons";
import axios from "axios";
import TextareaWithIcon from "../TextareaWithIcon";
import { CopyIcon } from "@chakra-ui/icons";
import { UilBrain } from '@iconscout/react-unicons'
import generateTitle from "../../utils/Prompts/titlePrompt";
import GenerateDescription from "../../utils/Prompts/descriptionPrompt";

function DetailInputs(props){
    const [details, setDetails] = useState("");
    const [keywords, setKeywords] = useState("");
    const [tone, setTone] = useState("");
    const [outputLang, setOutputLang] = useState("");
    const [responseField, setResponseField] = useState("");
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [type, setType] = useState("");
    const [isLoaded, setIsLoaded] = useState(true);



    function handleButton(event){
        event.preventDefault();
        if(type==="title"){
            onOpen()
            setIsLoaded(false);
            generateTitle(details, keywords, tone, outputLang)
            .then((response) => {
                setIsLoaded(true);
                var responseString = response.data.choices[0].text;
                responseString  = responseString.replace(/[";!?]/g,"");
                responseString = responseString.trim();
                setResponseField(responseString);
                console.log("Request resolved!")})
            .catch((error) => console.error(error));
        }

        if(type==="description"){
            onOpen()
            setIsLoaded(false);
            GenerateDescription(details, keywords, tone, outputLang)
            .then((response) => {
                setIsLoaded(true);
                var responseString = response.data.choices[0].text;
                responseString = responseString.replace(/[";]/g,"");
                responseString = responseString.trim();
                setResponseField(responseString);
                console.log("Request resolved!")})
            .catch((error) => console.error(error));
        }


    }

    return(
        
        <Stack spacing={4} p={2} rounded={8} bg="rgba(255,255,255,0.05)">
        <form onSubmit={handleButton} style={{width: "90vw"}}>
            <Stack spacing={4}>
                <FormControl isRequired>
                <FormLabel color={"primary.700"}>Product details</FormLabel>
                <Input
                  placeholder='Provide detailed information about your product.' 
                  value={details}
                  onChange={(event)=>{setDetails(event.target.value)}}
                  variant="solid"
                />
                </FormControl>

                <FormControl isRequired>
                <FormLabel color={"primary.700"}>Important keywords</FormLabel>
                <Input
                  placeholder='Brand name, USP, available colors/sizes, ...'
                  value={keywords}
                  onChange={(event)=>{setKeywords(event.target.value)}}
                  variant='solid'
                />
                </FormControl>
                <Stack direction={["column", "row"]}>
                  <FormControl as='fieldset' isRequired textAlign={"left"}>
                      <FormLabel as='legend' mb={0} color={"primary.700"}>Tone</FormLabel>
                          <Stack direction={["column", "column","column","row"]} spacing={2}>
                          <Select placeholder="Select tone" value={tone} onChange={(value)=>{setTone(value.target.value)}} variant="solid">
                            <option value='Professional'>Professional</option>
                            <option value='Friendly'>Friendly</option>
                            <option value='Innovative'>Innovative</option>
                            <option value='Persuasive'>Persuasive</option>
                            <option value='Funny'>Funny</option>
                            <option value='Futuristic'>Futuristic</option>
                            </Select>
                          </Stack>
                  </FormControl>
                
                  <FormControl as='fieldset' isRequired textAlign={"left"}>
                      <FormLabel as='legend' mb={0} color={"primary.700"}>Output Language</FormLabel>
                          <Stack direction={["column", "column","column","row"]} spacing={2}>
                            <Select placeholder="Select output language" value={outputLang} onChange={(value)=>{setOutputLang(value.target.value)}} variant="solid">
                            <option value='English'>English 🇬🇧</option>
                            <option value='German'>German 🇩🇪</option>
                            <option value='French'>French 🇫🇷</option>
                            <option value='Italian'>Italian 🇮🇹</option>
                            <option value='Russian'>Russian 🇷🇺</option>
                            <option value='Japanese'>Japanese 🇯🇵</option>
                            </Select>
                          </Stack>
                  </FormControl>
                </Stack>
              

              <Stack w={["90vw"]} direction={["column", "column", "row"]} justify={["none","space-between"]}>
                <Button type="submit" w={"inherit"} colorScheme="primary" rightIcon={<UilBrain></UilBrain>} onClick={()=>{setType("title")}}>Generate title</Button>
                <Button type="submit" w={"inherit"} colorScheme="primary" rightIcon={<UilBrain></UilBrain>} onClick={()=>{setType("description")}}>Generate description</Button>
                <Button isDisabled type="submit" w={"inherit"} colorScheme="primary" rightIcon={<UilBrain></UilBrain>} onClick={()=>{setType("bulletpoint")}}>Generate 5 bulletpoints</Button>
              </Stack>
              </Stack>
              
            
            </form>

            <Skeleton isLoaded={isLoaded} w={"auto"} fadeDuration={1}>
            <SlideFade in={isOpen} offsetY='20px'>
            <TextareaWithIcon width={"90vw"} icon={CopyIcon} value={responseField} onChange={(event)=>{setResponseField(event.target.value)}} color="primary.700" fontSize={["md","md","lg"]}></TextareaWithIcon>
            </SlideFade>
            </Skeleton>
            </Stack>
    )
}

export default DetailInputs;