import {
    Flex,
    Stack,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    Input,
    Text,
    Button,
    Link,
    InputRightElement,
    InputGroup
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { AuthContext } from '../utils/Authentication/AuthContext';
import { useContext } from 'react';
import { doc, serverTimestamp, getDoc, setDoc, deleteDoc } from "firebase/firestore";
import { db } from "../utils/Authentication/firebase";

export default function SettingsModal(props) {
    const [apiKeyState, setApiKeyState] = useState("")
    const {apiKey, saveApiKey, loadApiKey} = useContext(AuthContext);
    const [keyInputType, setKeyInputType] = useState("password")

    useEffect(() => {
        loadApiKey().then(key => {
            setApiKeyState(key)
        })
    },[]);
    
    function toggleKeyInputType(){
        if(keyInputType == "password"){
            setKeyInputType("text")
        } else {
            setKeyInputType("password")
        }
    }

    return (
        <Flex
        minH={'100vh'}
        align={'center'}
        justify={'center'}
        w="80vw"
        >    
            <ModalContent minW={["90vw","90vw", "50vw"]} maxW={["95vw","95vw", "50vw"]} verticalAlign={"center"} maxH={["95vh","95vh", "60vh"]} overflow={"auto"}>
            <ModalHeader>Account settings</ModalHeader>
            <ModalCloseButton m={2}/>
            <ModalBody>
        <Stack
            spacing={3}
            w={'full'}
            px={6}
            pb={6}
        >
            <Text fontWeight={"bold"} color='primary.700'>OpenAI API Key</Text>
            <InputGroup>
            <Input placeholder='Add your OpenAI API Key here' colorScheme='primary' type={keyInputType} value={apiKeyState} onChange={(e)=>{setApiKeyState(e.target.value)}}/>
            <InputRightElement ml={2}>
                <Button colorScheme='primary' onClick={()=>{toggleKeyInputType()}} >{keyInputType === "password" ? "Show" : "Hide"}</Button>
            </InputRightElement>
            </InputGroup>
            <br></br>
            <ol>
            <Text fontWeight={"bold"} color='primary.700'>Where do I find my OpenAI API Key?</Text>
                <li>
                <Text color='primary.700'>Go to OpenAI's Platform website at <Link fontWeight={"medium"} href="https://platform.openai.com" target='_blank' isExternal>platform.openai.com</Link> and sign in with an OpenAI account.</Text>
                </li>
                <li>
                <Text color='primary.700'>Click your profile icon at the top-right corner of the page and select "View API Keys."</Text>
                </li>
                <li>
                <Text color='primary.700'>Click "Create New Secret Key" to generate a new API key or use an exisiting one.</Text>
                </li>
                
            </ol>
            <br></br>

            <Stack direction={"row"} justify={"space-between"}>
                <Button colorScheme='primary' onClick={()=>{saveApiKey(apiKeyState)}} width={"full"}>Save changes</Button>
                <Button colorScheme='primary' onClick={()=>{alert(apiKey)}} width={"full"}>Save changes"""""</Button>

                
            </Stack>
        </Stack>
        </ModalBody>
        </ModalContent>
        </Flex>
    );
}
