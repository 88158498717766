import React, {useEffect, useState} from "react";
import { auth } from "./firebase";
import { onAuthStateChanged } from 'firebase/auth';

export const AuthContext = React.createContext();

export const AuthProvider = ({ children, value }) => {
    

    return (
        <AuthContext.Provider
          value={value}
        >
            {children}
        </AuthContext.Provider>
    )
}