import {
    Button,
    Flex,
    Input,
    Stack,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    Icon,
    Menu,
    MenuList,
    MenuItem,
    MenuButton,
    Text,
    Checkbox,
    Box,
    Spinner,
} from '@chakra-ui/react';

import * as Flags from 'country-flag-icons/react/3x2'
import { useState } from 'react';
import { FaCaretDown, FaExclamationTriangle } from 'react-icons/fa';
import toast from "react-hot-toast";


export default function ImportModal(props) {
    const [mpCountry, setMPCountry] = useState("United States");
    const [mpCountryCode, setMPCountryCode] = useState("US");
    const [asin, setAsin] = useState("");
    const [importTitle, setImportTitle] = useState(true);
    const [importDescription, setImportDescription] = useState(true);
    const [importBullet, setImportBullet] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const countries = [
        {country: "Germany", code: "DE", icon: <Icon as={Flags.DE} />},
        {country: "United Kingdom", code: "UK", icon: <Icon as={Flags.GB} />},
        {country: "Spain", code: "ES", icon: <Icon as={Flags.ES} />},
        {country: "France", code: "FR", icon: <Icon as={Flags.FR} />},
        {country: "Italy", code: "IT", icon: <Icon as={Flags.IT} />},
        {country: "Netherlands", code: "NL", icon: <Icon as={Flags.NL} />},
        {country: "Poland", code: "PL", icon: <Icon as={Flags.PL} />},
        {country: "Sweden", code: "SE", icon: <Icon as={Flags.SE} />},
        {country: "Turkey", code: "TR", icon: <Icon as={Flags.TR} />},
        {country: "Belgium", code: "BL", icon: <Icon as={Flags.BE} />},
        {country: "Japan", code: "JP", icon: <Icon as={Flags.JP} />},
        {country: "India", code: "IN", icon: <Icon as={Flags.IN} />},
        {country: "Australia", code: "AU", icon: <Icon as={Flags.AU} />},
        {country: "Singapore", code: "SG", icon: <Icon as={Flags.SG} />},
        {country: "United Arab Emirates", code: "UAE", icon: <Icon as={Flags.AE} />},
        {country: "Egypt", code: "EG", icon: <Icon as={Flags.EG} />},
        {country: "Saudi Arabia", code: "SA", icon: <Icon as={Flags.SA} />},
        {country: "United States", code: "USA", icon: <Icon as={Flags.US} />},
        {country: "Canada", code: "CA", icon: <Icon as={Flags.CA} />},
        {country: "Mexico", code: "MX", icon: <Icon as={Flags.MX} />},
        {country: "Brazil", code: "BR", icon: <Icon as={Flags.BR} />},
    ];

    const asinREGEX = /\b([A-Z0-9]{10})\b/;

    function submit(){
        if(asinREGEX.test(asin)){
            setIsLoading(true); props.parseFunction(mpCountryCode, asin, importTitle, importBullet, importDescription)
        } else {
            toast.error("ASIN doesn't match the format type.")
        }
    }
    return (
        <Flex
        minH={'100vh'}
        align={'center'}
        justify={'center'}
        >    
            <ModalContent w={["95vw","95vw", "50vw"]} verticalAlign={"center"}>
            <ModalHeader>Import Listing from Amazon</ModalHeader>
            <ModalCloseButton m={2}/>
            <ModalBody>
        <Stack
            spacing={7}
            w={'full'}
            maxW={'xl'}
            px={6}
            pb={6}
            >
                {(!isLoading && !props.isError) && 
                <>
                <Box>
                <Text fontWeight={"semibold"}>Product ASIN</Text>
                <Input variant='flushed' placeholder='Enter ASIN' value={asin} onChange={(e)=>{setAsin(e.target.value)}}/>
                </Box> 

                <Box>
                <Text fontWeight={"semibold"}>Amazon Marketplace</Text>

                <Menu w="full" closeOnSelect="false" isLazy>
                    <MenuButton
                    as={Button}
                    rightIcon={<Icon as={FaCaretDown} />}
                    colorScheme="primary"
                    w="full"
                    >
                    {mpCountry}
                    </MenuButton>
                    
                    <MenuList w="full">
                    <Stack h={"30vh"} overflowY="scroll" overflowX="hidden">
                    {countries.sort((a, b) => a.country.localeCompare(b.country)).map((country) => (
                    <MenuItem icon={country.icon} onClick={()=>{setMPCountry(country.country); setMPCountryCode(country.code)}} >
                        <Flex align="center">
                        <Text>{country.country}</Text>
                        </Flex>
                    </MenuItem>
                    ))}
                    </Stack>
                    </MenuList>
                    
                </Menu>
                </Box>

                <Stack direction={"column"}>
                    <Checkbox
                        isChecked={importTitle}
                        onChange={(e) => setImportTitle(e.target.checked)}
                    >Title
                    </Checkbox>

                    

                    <Checkbox
                        isChecked={importBullet}
                        onChange={(e) => setImportBullet(e.target.checked)}
                    >
                        Bulletpoints
                    </Checkbox>

                    <Checkbox

                        onChange={(e) => setImportDescription(e.target.checked)}
                        disabled
                    >Description
                    </Checkbox>
                </Stack>
                <Button
                    colorScheme="primary"
                    onClick={submit}
                    type="button"
                >
                    Import Listing
                </Button>
                </>
                }

                {(isLoading && !props.isError) &&
                
                <Stack direction="column" align="center"> 
                <Spinner></Spinner>
                
                <Text>Importing Amazon listing to Shopwrite...</Text>
                </Stack>
                
                }

                {props.isError &&
                <Stack direction="column" align="center" textAlign="center">
                <Icon as={FaExclamationTriangle} boxSize="10" color="primary.900"></Icon>
                <Text>{props.errorMsg}</Text>
                <Text>Make sure you've entered the right ASIN and selected the right marketplace country.</Text>
                <Button onClick={()=>{setIsLoading(false); props.setIsError(false);}}>Try again</Button>
                </Stack>
                }

                
        </Stack>
        </ModalBody>
        </ModalContent>
        </Flex>
    );
}