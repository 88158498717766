import {React, useContext} from "react";
import { 
  Stack
} from "@chakra-ui/react";
import DetailInputs from "../components/ShopwriteAI/DetailInputs";

import { TitleContext } from "../utils/GlobalTitleContext";

function AiGenerator(){
    const {setTitle, setSubtitle} = useContext(TitleContext)
    setTitle("ShopWrite AI")
    setSubtitle("")

    return(            
        <Stack direction={"column"} m={2} spacing={2} align={"center"} w={"90vw"}>
            <DetailInputs/>
        </Stack>
    )
}

export default AiGenerator;