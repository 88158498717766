import axios from "axios";
import { useContext } from "react";
import { AuthContext } from "../Authentication/AuthContext";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../Authentication/firebase";

export default function generateDescription(details, keywords, tone = "creative", outputLang, charLimit = 2000) {

    const data = {
        model: "text-davinci-003",
        prompt: `Write a ${tone} product description of maximum ${charLimit} characters for an Amazon product with the following details: ${details}. The description should include the following keywords: ${keywords}. The output should be in ${outputLang}.`,
        temperature: 0.7,
        max_tokens: 1000,
    };

    console.log("Request sent!")

    return axios.post("https://api.openai.com/v1/completions", data, {
        headers: {
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
            "Content-Type": "application/json",
        },
    });
}