import {
    Flex,
    Stack,
    Heading,
    Text,
    Button,
    Highlight
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../utils/Authentication/AuthContext';
import { useContext } from 'react';
import { toast } from 'react-hot-toast';
import { sendEmailVerification } from 'firebase/auth';
  
export default function EmailVerification() {
  const navigate = useNavigate();
  const {currentUser} = useContext(AuthContext)
  const [time, setTime] = useState(60)
  const {timeActive, setTimeActive} = useContext(AuthContext)

  

  useEffect(() => {
    const interval = setInterval(() => {
      currentUser?.reload()
      .then(() => {
        if(currentUser?.emailVerified){
          clearInterval(interval)
          navigate("/home");
        }
      })
      .catch((err) => {
        alert(err.message)
      })
    }, 1000)
  }, [currentUser])

  useEffect(() => {
    let interval = null
    if(timeActive && time !== 0 ){
      interval = setInterval(() => {
        setTime((time) => time - 1)
      }, 1000)
    }else if(time === 0){
      setTimeActive(false)
      setTime(60)
      clearInterval(interval)
    }
    return () => clearInterval(interval);
  }, [timeActive, time])

  const resendEmailVerification = () => {
    console.log("Verification email has been sent.")
    sendEmailVerification(currentUser)
    .then(() => {
      console.log("Email has been sent!")
      setTimeActive(true)
    }).catch((err) => {
      toast.error(err.message)
    })
  }

  return (
    <Flex
      align={'center'}
      justify={'center'}>
      <Stack spacing={4} textAlign="center" w={"40vw"}>
        <Stack align={'center'}>
          <Heading fontSize={'4xl'} textAlign={'center'}>
            Verify your Email Adress
          </Heading>
        </Stack>
        <Text>A verification email has been sent to {currentUser?.email && <Highlight query={currentUser?.email} styles={{px: '2', py: '1', fontWeight: 'bold', rounded: 'full', bg: 'primary.100' }}>{currentUser?.email}</Highlight>}</Text>
        <Text>Follow the instruction in the email to verify your account.</Text>
        <Button 
        onClick={resendEmailVerification}
        isDisabled={timeActive}
        
        colorScheme="primary"
        >Resend Email {timeActive && time}</Button>
      </Stack>
    </Flex>
  );
}