import { FaFacebookF, FaFacebookSquare, FaInstagram, FaLinkedinIn, FaTwitter, FaTwitterSquare } from "react-icons/fa"
import logo from "../media/logo_4k_color_transparent.png"
import { signOut } from "firebase/auth";
import { auth } from "../utils/Authentication/firebase";
import { Flex, Icon, Link, Text } from "@chakra-ui/react";

export const Footer = (props) => {
    return (
        <Flex
        w="full"
        px={20}
        py={5}
        alignItems="center"
        justifyContent="center"
        >
        <Flex
            w="full"
            as="footer"
            flexDir={{
            base: "column",
            sm: "row",
            }}
            align="center"
            justify="space-between"
            px="6"
            py="4"
        >
            <Link
            href="/"
            fontSize="xl"
            fontWeight="bold"
            color="gray.600"
            _hover={{
                color: "gray.700",
            }}
            >
            ShopWrite.io
            </Link>

            <Text
            py={{
                base: "2",
                sm: "0",
            }}
            color="gray.800"
            >
            © 2023 Felix Kins
            </Text>

            <Flex mx="-2">

            <Link
                href="https://www.facebook.com/profile.php?id=100094704453450"
                mx="2"
                color="gray.600"
                _hover={{
                color: "gray.500",
                }}
                aria-label="Facebook"
            >
                <Icon boxSize="5" as={FaFacebookSquare} fill="currentColor" />
            </Link>

            <Link
                href="https://twitter.com/shopwriteio"
                mx="2"
                color="gray.600"
                _hover={{
                    color: "gray.500",
                }}
                aria-label="Twitter"
            >
            <Icon boxSize="5" as={FaTwitterSquare} fill="currentColor" />
            </Link>
            </Flex>
        </Flex>
        </Flex>
    )
}

export default Footer;