import {
    Button,
    FormControl,
    Flex,
    Heading,
    Input,
    Stack,
    Text,
    useColorModeValue,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
} from '@chakra-ui/react';
import { SettingsVoice } from '@mui/icons-material';

import { auth, getMessageFromErrorCode } from '../../utils/Authentication/firebase';

import { sendPasswordResetEmail } from 'firebase/auth';
import { useState } from 'react';
import { toast } from 'react-hot-toast';

export default function ForgotPasswordForm(props) {
    const [email, setEmail] = useState("");

    function sendResetPassEmail(e){
        e.preventDefault();
        sendPasswordResetEmail(auth, email)
        .then(()=>{
            toast.success(`Reset password email has been sent to ${email}`,{duration: 5000})
            props.onClose();
        })
        .catch((error) => {
            console.log(error.code)
            toast.error(getMessageFromErrorCode(error))
        });
    }

    return (
        <Flex
        minH={'100vh'}
        align={'center'}
        justify={'center'}>
            
            <ModalContent w={["95vw","95vw", "50vw"]} verticalAlign={"center"}>
            <ModalCloseButton />
            <ModalHeader>Forgot your password?</ModalHeader>
            <ModalBody>
        <Stack
            spacing={4}
            w={'full'}
            maxW={'xl'}
            px={6}
            pb={6}
            >
            
            <Text
            fontSize={{ base: 'sm', sm: 'md' }}
            color={"primary.700"}>
            You&apos;ll get an email with a reset link
            </Text>
            
            <FormControl id="email" isRequired>
            <Input
                placeholder="Enter your email"
                _placeholder={{ color: 'primary.700' }}
                type="email"v
                value={email}
                onChange={(e)=>{setEmail(e.target.value)}}
            />
            </FormControl>
            <Stack>
            <Button type="submit" colorScheme="primary" onClick={sendResetPassEmail}>
                Request Reset
            </Button>
            </Stack>
            
        </Stack>
        </ModalBody>
        </ModalContent>
        </Flex>
    );
}