import './App.css';
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import ListingBuilder from "./pages/ListingBuilder";
import ListingEditor from "./pages/ListingEditor";
import SignUp from "./pages/SignUp";
import Login from "./pages/Login";
import PrivateRoutes from "./pages/PrivateRoutes";
import EmailVerification from "./components/Authentication/EmailVerification"
import { ChakraProvider, Stack, Spinner } from '@chakra-ui/react';
import customTheme from './utils/themes';
import { Toaster, toast } from 'react-hot-toast';
import { AuthProvider } from './utils/Authentication/AuthContext';
import { useEffect, useState } from 'react';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from "./utils/Authentication/firebase";
import AiGenerator from './pages/AiGenerator';
import { TitleProvider } from './utils/GlobalTitleContext';
import ListingUpgrader from './pages/ListingUpgrader';
import { AuthContext } from './utils/Authentication/AuthContext';
import { useContext } from 'react';
import { doc, serverTimestamp, getDoc, setDoc, deleteDoc } from "firebase/firestore";
import { db } from "./utils/Authentication/firebase";

function App() {
  const [currentUser, setCurrentUser] = useState(null)
  const [timeActive, setTimeActive] = useState(false)
  const [pending, setPending] = useState(true);
  const [apiKey, setApiKey] = useState("");

  useEffect(()=>{

    onAuthStateChanged(auth, async (user)=>{
      setPending(true)
        if(user){
            setCurrentUser(user);
            setPending(false);
        } else {
            setCurrentUser(null);
            setPending(false);
        }
        
    })

    

  }, []);

  useEffect(()=>{
    loadApiKey().then(key => {
      setApiKey(key);
    });
  }, [saveApiKey]);

  async function loadApiKey() {
    if (!currentUser) return null;
  
    const documentRef = doc(db, 'users', currentUser.uid);
    const documentSnapshot = await getDoc(documentRef);
  
    if (documentSnapshot.exists()) {
      var key = documentSnapshot.data().openAiKey;
      setApiKey(key);
      console.log(key);
      return key;
    }
  
    return null;
  }
  
  async function saveApiKey(apiKey){
    if(!currentUser) return;
    toast.promise(
      setDoc(doc(db, "users", currentUser.uid), {
      openAiKey: apiKey
    }),
    {
      loading: "Saving API Key...", 
      success: "Sucessfully saved.", 
      error: (err) => `Something went wrong: ${err.toString()}`
    })
  }

  
  
  if(pending){
      return <Stack direction={"row"} align="center">
        <Stack direction={"column"} align="center">
        <Spinner
          thickness='4px'
          speed='0.65s'
          emptyColor='gray.200'
          color='primary.500'
          size='xl'
        />
        </Stack>
      </Stack>
  }
  
  return (
    <Stack className="App" direction='column' align={"center"}>
    <TitleProvider>
    <AuthProvider value={{currentUser, timeActive, setTimeActive, apiKey, saveApiKey, loadApiKey}}>
    <ChakraProvider theme={customTheme}>
    
      <BrowserRouter>
      <Navbar/>
        <Stack minH={"100vh"}>
        <Routes>
            <Route path='*' element={"404 - Page not found"} />
            <Route path="/" element={<Home/>} exact></Route>
            <Route element={<PrivateRoutes />}>
              <Route path="/home" element={<Home/>} exact></Route>
              <Route path="/listing-ai" element={<AiGenerator isPremium />}/>
              <Route path="/listing-upgrader" element={<ListingUpgrader/>}/>
              <Route path="/listing-builder" element={<ListingBuilder isPremium/>}>
              </Route>
              <Route path="/listing-builder/edit/:id" element={ <ListingEditor isPremium/> }/>
            </Route>
            <Route path="/signup" element={<SignUp/>} />
            <Route path="/login" element={<Login/>} />
            <Route path="/verify-email" element={<EmailVerification/>} />
          </Routes>
        </Stack>
        <Footer/>
      </BrowserRouter>
    </ChakraProvider>
    </AuthProvider>
    </TitleProvider>
    <Toaster
      position='bottom-center'
    />
    </Stack>
    
  );
}

export default App;
