import { extendTheme } from "@chakra-ui/react";
import { StepsTheme as Steps } from "chakra-ui-steps";


const colors = {
    primary: {
      "50": "#ECF9F7",
      "100": "#C9EEE9",
      "200": "#A6E3DB",
      "300": "#83D8CD",
      "400": "#60CCBF",
      "500": "#3EC1B1",
      "600": "#319B8E",
      "700": "#25746A",
      "800": "#194D47",
      "900": "#0C2723"
    },
    delete: {
      "50": "#FCE8E8",
      "100": "#F8BFBF",
      "200": "#F39595",
      "300": "#EF6C6C",
      "400": "#EA4343",
      "500": "#E51A1A",
      "600": "#B71515",
      "700": "#8A0F0F",
      "800": "#5C0A0A",
      "900": "#2E0505"
    },
    components: {
      Input: {
        baseStyle: {
          field: {
            focusBorderColor: "primary.200"
          }
        }
      },
      Button: {
        baseStyle: {
          bg: "primary.700"
        }
      },
      Steps
    }
  };
  
  const customTheme = extendTheme({ colors });
  
  export default customTheme;
  