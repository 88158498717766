import {React, useState} from "react";
import { 
  Text,
  InputGroup,
  Input,
  Wrap,
  WrapItem,
  InputRightElement,
  Card,
  Button,
  Stack,
  Accordion, AccordionItem, AccordionButton, Box, AccordionIcon, AccordionPanel
} from "@chakra-ui/react";
import { AddIcon } from '@chakra-ui/icons';
import { Scrollbar } from 'react-scrollbars-custom';
import { UilTrashAlt as DeleteIcon} from '@iconscout/react-unicons'
import { toast } from "react-hot-toast";


function KeywordData(props){

    const [keywordEntry, setKeywordEntry] = useState("");
    

    function checkInclude(word){
        const regex = new RegExp("\\b" + word + "\\b");
        return regex.test(props.allEntries.toLowerCase());
    }

    function addKeywords(string) {
        string.split(",").forEach((e)=>{
            e = e.toLowerCase();
            e = e.trim();
            if(e !== "" && e !== " " && !props.keywords.includes(e)){
                props.setKeywords(keywords => [...keywords, e]);
            }
        });
        setKeywordEntry("");
    }

    function deleteAllKeywords() {
        if(window.confirm("Do you want to delete all keywords?")){
            props.setKeywords("");
            toast.success("All keywords deleted.")
        }
        
    }


    return(
        <Accordion defaultIndex={[0]} allowMultiple>
        <AccordionItem align={"center"} w={["95vw","95vw","30vw"]} zIndex={0} border={0}>
        {({ isExpanded }) => (
        <Card p={3}>
        <AccordionButton px={1}>
        <Stack direction={"row"} justify="space-between" w="100%" align={"center"}>
            <Text color={"primary.700"} fontWeight={"semibold"} fontSize={["md","md","xl"]}>Keyword Database</Text>
            {isExpanded ? <Button zIndex={3} colorScheme={"red"} variant='outline' size={["xs","xs","xs","sm"]} mt={2} rightIcon={<DeleteIcon></DeleteIcon>} onClick={(e)=>{e.stopPropagation(); deleteAllKeywords()}}>Delete all keywords</Button> : <></>}<AccordionIcon/>
            </Stack>
            </AccordionButton> 
            
            
            <AccordionPanel px={1}>
            <InputGroup mb={3} w="inherit">
                
            <Input placeholder="One by one or comma-seperated" value={keywordEntry} onChange={(event)=>{setKeywordEntry(event.target.value)}} onKeyPress={e=> {
                if (e.key === 'Enter') {
                    addKeywords(keywordEntry)
                }
            }}>
                
            </Input>
            
            <InputRightElement onClick={()=>addKeywords(keywordEntry)}><AddIcon></AddIcon></InputRightElement>
            </InputGroup>
            <Card border="solid" borderColor={"lightGrey"} borderWidth={"1px"} borderRadius={"5px"} px={2} w={"100%"} h={"48vh"}>
            <Scrollbar h={"100%"} w={"100%"}>
            <Wrap spacing='10px' flexWrap={"wrap"} overflowX={"hidden"} h={"100%"} id="noScrollbar" m={2} mr={5}>
            {props.keywords[0] && props.keywords.map(currentString => {
                return(
                    <WrapItem key={currentString} alignItems={"center"} opacity={checkInclude(currentString) ? "1" : "0.3"}>
                        <Text fontSize={"sm"} color={checkInclude(currentString) ? "primary.700" : "red"} fontWeight={"medium"} decoration={"None"} >
                            {currentString}
                        </Text>
                        <DeleteIcon boxSize={4} color={"black"} _hover={{ transitionDuration: '0.3s', color: "primary.700" }} ml={0.5} onClick={()=>{props.setKeywords(props.keywords.filter(e => e !== currentString))}}></DeleteIcon>
                    </WrapItem>
                )}
            )}
            {
                !props.keywords[0] &&
                <>
                <Text color={"primary.700"} fontWeight={"bold"} fontSize={["sm","sm","md"]}>
                No keywords added yet. 
                    <Text color={"primary.700"} fontWeight={"normal"} fontSize={["sm","sm","md"]}>
                    Add keywords by submitting them one-by-one or as a comma seperated list in the input field above.
                    </Text>
                </Text>
                
                </>
            }
            </Wrap>
            </Scrollbar>
            </Card>
            </AccordionPanel>
            </Card>
            )}
            </AccordionItem>
            </Accordion>
    )
}

export default KeywordData;