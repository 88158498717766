import {React, useEffect, useState, useRef } from "react";
import { 
  Stack,
  Text,
  Button,
  Modal,
  ModalOverlay,
  useDisclosure,
  Divider
} from "@chakra-ui/react";
import { AuthContext } from '../utils/Authentication/AuthContext';
import { useContext } from 'react';
import { collection, serverTimestamp, addDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { db } from "../utils/Authentication/firebase";
import { toast } from "react-hot-toast";
import ListingOverview from "../components/ListingBuilder/ListingOverview";
import { useNavigate } from "react-router-dom";
import { TitleContext } from "../utils/GlobalTitleContext";
import ImportModal from "../components/AmazonImport/ImportModal";
import { functions } from "../utils/Authentication/firebase";



function ListingBuilder(props){
    const {currentUser} = useContext(AuthContext);
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const inputsRef = useRef(null);
    const [isError, setIsError] = useState(false);
    const {setTitle, setSubtitle} = useContext(TitleContext)
    setTitle("Listing Builder")
    setSubtitle("Build your E-Commerce listing based on keywords, SEO and norms.")

    const [errorMessage, setErrorMessage] = useState("")
    const [inputs, setInputs] = useState({
        title: "",
        bullets: {
            bullet1: "",
            bullet2: "",
            bullet3: "",
            bullet4: "",
            bullet5: ""
        },
        description: ""
    });

    useEffect(()=>{
        // Skip the first render
    if (inputsRef.current !== null) {
        // Run your logic here
        uploadListingToDb();
      } else {
        // Update the ref to indicate subsequent renders
        inputsRef.current = true;
      }
    }, [inputs]);
    
    const uploadListingToDb = async () => {
        let id = 0;

        await toast.promise(
            addDoc(collection(db, "users", currentUser.uid, "listings"), {
            keywords: [],
            listing: {...inputs},
            timestamp: serverTimestamp()
        }).then((docRef)=> {
            id = docRef.id;
        }),
        {
            loading: "Creating listing...", 
            success: "Sucessfully created listing", 
            error: (err) => `Something went wrong: ${err.toString()}`
        })

        navigate(`/listing-builder/edit/${id}`)
    }

    const parseListingFromAmazon = async ( mpCountry, asin, titleBool, bulletsBool, descBool) => {
        const parseAmazonProduct = httpsCallable(functions, 'parseAmazonProduct');
        parseAmazonProduct({ asin, mpCountry })
        .then(result => {
            console.log("Clientside success")
            console.log(result);
            console.log(result.data.bullets);
            console.log(result.data.title);

            if(titleBool){
                setInputs(prevInputs => ({
                    ...prevInputs,
                    title: result.data.title
                }));
            }
            if(bulletsBool){
                setInputs(prevInputs => ({
                    ...prevInputs,
                    bullets: {
                        ...result.data.bullets.reduce((acc, bullet, index) => {
                        acc[`bullet${index + 1}`] = bullet;
                        return acc;
                      }, {})
                    }
                }));
            }
            
        })
        .catch(error => {
            setIsError(true);
            console.log("Clientside error")
            if (error.message) {
                console.error(error.message);
                setErrorMessage(error.message);
            } else {
                console.error(error);
                setErrorMessage("Something went wrong. Try again.");
            }
        });

        
    }



    return (
        <Stack direction={"column"} m={2} spacing={2} maxW={"95vw"} p={2}>
            <Stack direction={"column"}>
            
            <ListingOverview></ListingOverview>

                <Stack direction={"column"}  align={"center"}>
                <Stack direction={["column","column","column","row"]} align={"center"} w="35%" justify={"space-between"}>
                
                    <Button onClick={uploadListingToDb} variant="solid" bg="white">Create a new listing</Button>
                    <Stack align='center' direction="row">
                        <Divider w={"20px"} borderColor="black"/>
                        <Text>or</Text>
                        <Divider w={"20px"} borderColor="black"/>
                    </Stack>
                    <Button onClick={onOpen} variant="solid" bg="white">Import from Amazon</Button>
                    
                </Stack>
                </Stack>
            </Stack>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay/>
                <ImportModal parseFunction={parseListingFromAmazon} errorMsg={errorMessage} isError={isError} setIsError={setIsError}/>
            </Modal>
        
        </Stack>
    )
}

export default ListingBuilder;