import { Box, Card, CardBody, Heading, IconButton, Stack, Text, Input, InputGroup, InputRightElement, Skeleton} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { AuthContext } from '../../utils/Authentication/AuthContext';
import { useContext } from 'react';
import { collection, doc, getDocs, deleteDoc, query, orderBy } from "firebase/firestore";
import { db } from "../../utils/Authentication/firebase";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { useNavigate, useSearchParams } from "react-router-dom";
import { UilSearch } from '@iconscout/react-unicons'
import { toast } from "react-hot-toast";

function ListingOverview(){
    const[isLoading, setIsLoading] = useState(false);
    const[listings, setListings] = useState([]);
    const[filteredListings, setFilteredListings] = useState([]);
    const[searchQuery, setSearchQuery] = useState("");
    const {currentUser} = useContext(AuthContext);

    async function fetchListings(){
        const querySnapshot = await getDocs(query(collection(db, 'users', currentUser.uid, "listings"), orderBy("timestamp", "desc")))
        querySnapshot.forEach((doc) => {
            
            var tempObj = doc.data();
            tempObj["id"] = doc.id;
            setListings(listings => [...listings, tempObj])
        })
        
    }

    async function deleteListing(e, id){
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        var listing = listings.find(item=> item.id === id);
        var title = listing.listing.title;
        
        if(window.confirm(`Are you sure you want to delete "${title ? title.length > 20 ? title.slice(0, 20) + "..." : title : "Untitled listing "+listing.timestamp.seconds}"?`)){
            await deleteDoc(doc(db, "users", currentUser.uid, "listings", id))
            setListings(listings.filter(item => item.id !== id))
            toast.success(`Listing "${title ? title.length > 20 ? title.slice(0, 20) + "..." : title : "Untitled listing "+listing.timestamp.seconds}" deleted`)
        }
    }

    useEffect(()=>{
        setIsLoading(true)
        fetchListings().then(()=>{
            setIsLoading(false)
        })
    },[]);

    useEffect(()=>{
        setFilteredListings(listings.filter((e)=>{return e.listing.title.toLowerCase().includes(searchQuery.toLowerCase())}))
    }, [searchQuery])

    const navigate = useNavigate();

    return(
        <Box w={"90vw"}>
        <InputGroup mb={2}>
        <Input placeholder="Search listing" value={searchQuery} onChange={(e)=>{setSearchQuery(e.target.value)}} variant="solid"></Input>
        <InputRightElement><UilSearch></UilSearch></InputRightElement>
        </InputGroup>
        
        {(listings && !searchQuery) && listings.map((listing)=>(
                <Card w={"90vw"} mb={4} key={listing.id} _hover={{
                    bg: "primary.50",
                    transitionDuration: "0.2s"
                }}>
                    <CardBody>
                        <a href={`/listing-builder/edit/${listing.id}`}>
                        <Stack direction={"row"} justify="space-between" align={"center"}>
                        <Box w={"70%"} px={0}>
                            <Heading size={["xs","xs","md"]} color={"primary.700"} fontWeight={"semibold"} isTruncated>
                            {!listing.listing.title ? "Untitled Listing "+listing.timestamp.seconds : listing.listing.title}
                            </Heading>
                            <Text color={"primary.700"}>Last edit: {listing.timestamp.toDate().toLocaleDateString()} {listing.timestamp.toDate().toLocaleTimeString()}</Text>
                        </Box>
                        <Stack direction={"row"} spacing={1} w={"30%"} justify="right">
                        <IconButton boxSize={[9,9,10]} icon={<DeleteIcon></DeleteIcon>} colorScheme={"red"} onClick={(e)=>{e.stopPropagation(); e.preventDefault(); deleteListing(e, listing.id)}}></IconButton>
                        <IconButton boxSize={[9,9,10]} icon={<EditIcon></EditIcon>} colorScheme={"primary"} onClick={(e)=>{e.stopPropagation(); e.preventDefault(); navigate(`/listing-builder/edit/${listing.id}`)}}></IconButton>
                        </Stack>
                        </Stack>
                        </a>
                    </CardBody>
                    
                </Card>
            ))}
            {(listings && searchQuery) && filteredListings.map((listing)=>(
                <Card w={"90vw"} mb={4} key={listing.id} _hover={{
                    bg: "primary.50",
                    transitionDuration: "0.2s"
                }}>
                    <CardBody onClick={()=>{navigate(`/listing-builder/edit/${listing.id}`)}} >
                        <Stack direction={"row"} justify={"space-between"} align={"center"}>
                        <Box maxW={"60vw"}>
                            <Heading size={["xs","xs","md"]} color={"primary.700"} fontWeight={"semibold"} isTruncated>
                            {!listing.listing.title ? "Untitled Listing "+listing.timestamp.seconds : listing.listing.title}
                            </Heading>
                            <Text color={"primary.700"}>Last edit: {listing.timestamp.toDate().toLocaleDateString()} {listing.timestamp.toDate().toLocaleTimeString()}</Text>
                        </Box>
                        <Stack direction={"row"} spacing={1} maxW={"35vw"}>
                        <IconButton boxSize={[9,9,10]} icon={<DeleteIcon></DeleteIcon>} colorScheme={"red"} onClick={(e)=>{e.stopPropagation(); deleteListing(e, listing.id)}}></IconButton>
                        <IconButton boxSize={[9,9,10]} icon={<EditIcon></EditIcon>} colorScheme={"primary"} onClick={()=>{navigate(`/listing-builder/edit/${listing.id}`)}}></IconButton>
                        </Stack>
                        </Stack>
                    </CardBody>
                    
                </Card>
            ))}
            {isLoading && 
                <Card w={"90vw"} mb={4} key={"LoadingListing"} _hover={{
                    bg: "primary.50",
                    transitionDuration: "0.2s"
                }}>
                    <CardBody>
                        <Stack direction={"row"} justify={"space-between"} align={"center"}>
                        <Box maxW={"60vw"}>
                        <Skeleton isLoaded={!isLoading} startColor='primary.500' endColor='grey' mb={2}>
                            <Heading size='xs' color={"primary.700"} isTruncated width={"100%"}>
                            {"Invisible Listing Title - Only for loading purposes -------------------------------------------------"}
                            </Heading>
                        </Skeleton>
                            <Skeleton isLoaded={!isLoading} startColor='primary.500' endColor='grey' mb={2}>
                            <Text color={"primary.700"}>Last edit: {"01.01.1990"} {"11:11:11"}</Text>
                            </Skeleton>
                        </Box>
                        <Stack direction={"row"} spacing={1} maxW={"35vw"}>
                        <Skeleton isLoaded={!isLoading} startColor='primary.500' endColor='grey'>
                        <IconButton boxSize={[9,9,10]} icon={<DeleteIcon></DeleteIcon>} colorScheme={"red"}></IconButton>
                        </Skeleton>
                        <Skeleton isLoaded={!isLoading} startColor='primary.500' endColor='grey'>
                        <IconButton boxSize={[9,9,10]} icon={<EditIcon></EditIcon>} colorScheme={"primary"}></IconButton>
                        </Skeleton>
                        </Stack>
                        </Stack>
                    </CardBody>
                    
                </Card>
                
            }
            
            
        
        
            
        </Box>
    )
}

export default ListingOverview;