import axios from "axios";
import { useContext } from "react";
import { AuthContext } from "../Authentication/AuthContext";

export default function generateTitle(details, keywords, tone, outputLang, charLimit = 200) {

    const data = {
      model: "text-davinci-003",
      prompt: `Write a product title of ${charLimit} characters for an Amazon product with the following details: ${details}. The title should include the following keywords: ${keywords}. The title should not include adjectives. The tone should be ${tone}. The output should be in ${outputLang}.`,
      temperature: 0.7,
      max_tokens: 1000,
    };

    console.log("Request sent!")
  
    return axios.post("https://api.openai.com/v1/completions", data, {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
        "Content-Type": "application/json",
      },
    });
}