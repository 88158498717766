import React from "react";
import { Box, Flex, Icon, chakra, Stack, Center } from "@chakra-ui/react";
import { FaPencilAlt, FaGlobe } from "react-icons/fa";
import { AiOutlineGlobal } from "react-icons/ai"

export default function Features(props) {
    const Feature = (props) => {
        return (
          <Flex>
            <Flex shrink={0}>
              <Flex
                alignItems="center"
                justifyContent="center"
                h={12}
                w={12}
                rounded="md"
              >
                <Icon
                  boxSize={6}
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  {props.icon}
                </Icon>
              </Flex>
            </Flex>
            <Box ml={4}>
              <chakra.dt
                fontSize="lg"
                fontWeight="medium"
                lineHeight="6"
                _light={{
                  color: "gray.900",
                }}
              >
                {props.title}
              </chakra.dt>
              <chakra.dd
                mt={2}
                color="gray.500"
                _dark={{
                  color: "gray.400",
                }}
              >
                {props.children}
              </chakra.dd>
            </Box>
          </Flex>
        );
      };
    
      return (
        <Flex
          p={[5,5,5,20]}
          w="auto"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            py={12}
            bg="primary.100"
            _dark={{
              bg: "gray.800",
            }}
            rounded="xl"
          >
            <Box
              maxW="9xl"
              mx="auto"
              px={{
                base: 4,
                lg: 8,
              }}
            >
              <Box
                textAlign={{
                  lg: "center",
                }}
              >
                <chakra.h2
                  _light={{
                    color: "brand.600",
                  }}
                  fontWeight="semibold"
                  textTransform="uppercase"
                  letterSpacing="wide"
                >
                  ShopWrite
                </chakra.h2>
                <chakra.p
                  mt={2}
                  fontSize={{
                    base: "3xl",
                    sm: "4xl",
                  }}
                  lineHeight="8"
                  fontWeight="extrabold"
                  letterSpacing="tight"
                  _light={{
                    color: "gray.900",
                  }}
                >
                  A better way to write product copies.
                </chakra.p>
                <chakra.p
                  mt={4}
                  maxW="2xl"
                  fontSize="xl"
                  mx={{
                    lg: "auto",
                  }}
                  color="gray.500"
                  _dark={{
                    color: "gray.400",
                  }}
                >
                  Assisted by artificial intelligence, ShopWrite provides optimized titles, descriptions and more for your product.
                </chakra.p>
              </Box>
    
              <Box mt={10}>
                <Stack
                  spacing={{
                    base: 10,
                    md: 0,
                  }}
                  display={{
                    md: "grid",
                  }}
                  gridTemplateColumns={{
                    md: "repeat(2,1fr)",
                  }}
                  gridColumnGap={{
                    md: 8,
                  }}
                  gridRowGap={{
                    md: 10,
                  }}
                >
                  <Feature
                    title="Higher organic ranking"
                    icon={
                      <Icon as={FaGlobe} color="black" className="icon"></Icon>
                    }
                  >
                    Organic ranking increases due to the AI assisted creation of product texts. Amazon recognizes important keywords easier and ranks the product accordingly.
                  </Feature>
    
                  <Feature
                    title="Better conversion-rate"
                    icon={
                      <path
                        color="black"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M3 6l3 1m0 0l-3 9a5.002 5.002 0 006.001 0M6 7l3 9M6 7l6-2m6 2l3-1m-3 1l-3 9a5.002 5.002 0 006.001 0M18 7l3 9m-3-9l-6-2m0-2v2m0 16V5m0 16H9m3 0h3"
                      />
                    }
                  >
                    Customers understand well written product copies faster and don't waste time trying to understand grammatically incorrect bullet points.
                  </Feature>
    
                  <Feature
                    title="Professional Listing-Builder"
                    icon={
                      <Icon as={FaPencilAlt} color="black"></Icon>
                    }
                  >
                    Use the ShopWrite listing building tool to gain a leverage over your competition. Keyword based writing, Amazon Import and Listing Evaluation. <a href="/listing-builder">Check it out now!</a>
                  </Feature>
    
                  <Feature
                    title="Modern way of E-Commerce"
                    icon={
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
                        color="black"
                      />
                    }
                  >
                    Artificial Intelligence is the one of the most polarizing technologies of the century. Take part in the AI era now. Stop paying copywriters, use ShopWrite.
                  </Feature>
                </Stack>
              </Box>
            </Box>
          </Box>
        </Flex>
      );
}