import React from "react";
import { 
    Button,
} from "@chakra-ui/react";
import { FaGoogle, FaFacebook, FaYahoo } from "react-icons/fa";
import { signInWithPopup } from "firebase/auth";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { auth, googleProvider, facebookProvider, yahooProvider } from "../../utils/Authentication/firebase";

function ExternalAuth(){
    const navigate = useNavigate();
    return (
        <>
        <Button
        colorScheme={"primary"}
        variant='outline'
        leftIcon={<FaGoogle />}
        iconSpacing={10}
        onClick={() => {
            signInWithPopup(auth, googleProvider)
            .then((user) => {
                navigate("/home");
            })
            .catch((e) => {
                toast.error(e.message)
            })
        }}
        >
        Continue with Google
        </Button>
        </>
    )
}

export default ExternalAuth;