import React, { useContext } from "react";
import Features from "../components/LandingPage/Features";
import Hero from "../components/LandingPage/Hero";
import { TitleContext } from "../utils/GlobalTitleContext";

function Home(){
    const {setTitle, setSubtitle} = useContext(TitleContext)
    setTitle("")
    setSubtitle("")
    return(
        <>
        <Hero/>
        <Features/>
        </>
    )
}

export default Home;