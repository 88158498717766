import {
    Flex,
    Box,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    InputRightElement,
    Stack,
    Button,
    Heading,
    Text,
    useColorModeValue,
    Link,
} from '@chakra-ui/react';
import { useState } from 'react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import { auth } from '../../utils/Authentication/firebase';
import { createUserWithEmailAndPassword, sendEmailVerification, signOut } from 'firebase/auth';
import DividerWithText from './DividerWithText';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import ExternalAuth from './ExternalAuth';
import { AuthContext } from '../../utils/Authentication/AuthContext';
import { useContext } from 'react';
import EmailVerification from './EmailVerification';
  
export default function SignupCard() {
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const navigate = useNavigate();
  const {currentUser} = useContext(AuthContext);
  const {setTimeActive} = useContext(AuthContext);
  const [verifyFlag, setVerifyFlag] = useState(false);


  const signUp = (e) => {
      e.preventDefault();
      

      try {
        if(password !== repeatPassword){
          throw new Error("Passwords do not match.")
        }
        createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          console.log(userCredential);
          sendEmailVerification(userCredential.user)
          .then(()=>{
            setVerifyFlag(true)
            setTimeActive(true)
          }).catch((error) => {
            console.log(error);
            toast.error(error.message)
          }); 
        })
        .catch((error) => {
            console.log(error);
            toast.error(error.message)
        });
      } catch (error) {
        console.log(error);
        toast.error(error.message)
      }
      
  }

  if(verifyFlag){
    return(<EmailVerification></EmailVerification>)
  }

  return (
      <Flex
      align={'center'}
      justify={'center'}>
      <Stack spacing={8} mx={["none",'auto']} maxW={'90vw'} py={12} px={0} w={["90vw", "90vw", "40vw","40vw"]}>
        <Stack align={'center'}>
          <Heading fontSize={'4xl'} textAlign={'center'}>
            Sign up
          </Heading>
          <Text fontSize={['md','lg']} color={'gray.600'}>
            to enjoy all of our magical AI tools ✨
          </Text>
        </Stack>
        <form onSubmit={signUp}>
        <Box
          rounded={'lg'}
          bg={'white'}
          boxShadow={'lg'}
          p={8}>
          <Stack spacing={4}>
            <FormControl id="email" isRequired>
              <FormLabel>Email address</FormLabel>
              <Input type="email" value={email} onChange={(event)=>{setEmail(event.target.value)}} focusBorderColor="primary.200"/>
            </FormControl>
            <FormControl id="password" isRequired>
              <FormLabel>Password</FormLabel>
              <InputGroup>
                <Input type={showPassword ? 'text' : 'password'} value={password} onChange={(event)=>{setPassword(event.target.value)}} focusBorderColor="primary.200"/>
                <InputRightElement h={'full'}>
                  <Button
                    variant={'ghost'}
                    onClick={() =>
                      setShowPassword((showPassword) => !showPassword)
                    }
                    _hover={{
                      bg: "primary.100"
                    }}
                    >
                    {showPassword ? <ViewIcon /> : <ViewOffIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>

            <FormControl id="password" isRequired>
              <FormLabel>Repeat password</FormLabel>
              <InputGroup>
                <Input type={showRepeatPassword ? 'text' : 'password'} value={repeatPassword} onChange={(event)=>{setRepeatPassword(event.target.value)}} focusBorderColor="primary.200"/>
                <InputRightElement h={'full'}>
                  <Button
                    variant={'ghost'}
                    onClick={() =>
                      setShowRepeatPassword((showRepeatPassword) => !showRepeatPassword)
                    }
                    _hover={{
                      bg: "primary.100"
                    }}
                    >
                    {showRepeatPassword ? <ViewIcon /> : <ViewOffIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>

            <Stack spacing={10} pt={2}>
              <Button
                type='submit'
                loadingText="Submitting"
                size="lg"
                bg={'primary.400'}
                color={'white'}
                _hover={{
                  bg: 'primary.500',
                }}>
                Sign up
              </Button>
            </Stack>
            <Text align={'center'}>
              Already a user? <Link color={'primary.400'} href="/login">Login</Link>
            </Text>

            <DividerWithText>OR</DividerWithText>

            <ExternalAuth />
          </Stack>
        </Box>
        </form>
      </Stack>
    </Flex>
  );
}