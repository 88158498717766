import {React, useEffect, useState, useRef } from "react";
import { 
  Stack,
  Heading,
  Text,
  Button,
  Box,
  Modal,
  ModalOverlay,
  useDisclosure,
  Divider,
  Flex,
  Input
} from "@chakra-ui/react";
import { AuthContext } from '../utils/Authentication/AuthContext';
import { useContext } from 'react';
import { collection, getDocs, serverTimestamp, addDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { db } from "../utils/Authentication/firebase";
import { toast } from "react-hot-toast";
import ListingOverview from "../components/ListingBuilder/ListingOverview";
import { useNavigate } from "react-router-dom";
import { TitleContext } from "../utils/GlobalTitleContext";
import ImportModal from "../components/AmazonImport/ImportModal";
import { functions } from "../utils/Authentication/firebase";
import { Steps, Step, useSteps } from "chakra-ui-steps";




function ListingUpgrader(props){
    const {currentUser} = useContext(AuthContext);
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure()
    const inputsRef = useRef(null);
    const [isError, setIsError] = useState(false);
    const {setTitle, setSubtitle} = useContext(TitleContext)
    setTitle("Listing Upgrader")
    setSubtitle("Upgrade your Amazon listings to another level with only a few clicks.")

    const { nextStep, prevStep, reset, activeStep } = useSteps({
        initialStep: 0,
    });

    const [errorMessage, setErrorMessage] = useState("")
    const [inputs, setInputs] = useState({
        title: "",
        bullets: {
            bullet1: "",
            bullet2: "",
            bullet3: "",
            bullet4: "",
            bullet5: ""
        },
        description: ""
    });


    const parseListingFromAmazon = async ( mpCountry, asin, titleBool, bulletsBool, descBool) => {
        const parseAmazonProduct = httpsCallable(functions, 'parseAmazonProduct');
        parseAmazonProduct({ asin, mpCountry })
        .then(result => {
            console.log(result);
            console.log(result.data.bullets);
            console.log(result.data.title);

            if(titleBool){
                setInputs(prevInputs => ({
                    ...prevInputs,
                    title: result.data.title
                }));
            }
            if(bulletsBool){
                setInputs(prevInputs => ({
                    ...prevInputs,
                    bullets: {
                        ...result.data.bullets.reduce((acc, bullet, index) => {
                        acc[`bullet${index + 1}`] = bullet;
                        return acc;
                      }, {})
                    }
                }));
            }
            
        })
        .catch(error => {
            setIsError(true);
            console.log("Clientside error")
            if (error.message) {
                console.error(error.message);
                setErrorMessage(error.message);
            } else {
                console.error(error);
                setErrorMessage("Something went wrong. Try again.");
            }
        });

        
    }



    return (
        <Stack direction={"column"} m={2} spacing={2} maxW={"95vw"} p={2}>
        <Steps activeStep={activeStep}>
            <Step label="Step 1" description="This is the first step" />
            <Step label="Step 2" description="This is the second step" />
            <Step label="Step 3" description="This is the third step" />
        </Steps>
        <Button onClick={() => prevStep()}>Back</Button>
        <Button onClick={() => nextStep()}>Next</Button>
        
        </Stack>
    )
}

export default ListingUpgrader;