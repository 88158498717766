import { 
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    Box,
    Button,
    Card, Input, InputGroup, InputRightElement, Stack, Text 
} from "@chakra-ui/react";
import React, { useState } from "react";
import { toast } from "react-hot-toast";
import Bulletpoint from "./Bulletpoint";

const BULLET_CHAR_LIMIT = 200;

function BulletsBox({bullets, setBullets}){
    
    

    return (
        <Accordion defaultIndex={[0]} allowMultiple>
        <AccordionItem border={0}>
        <Card p={3} h={"auto"}>
            <AccordionButton px={1}>
            <Box as="span" flex='1' textAlign={"left"}><Text color={"primary.700"} fontWeight={"semibold"} fontSize={["md","md","xl"]}>Bullet Points</Text></Box><AccordionIcon/>
            </AccordionButton>
            <AccordionPanel px={1}>
            <Stack direction={"column"} spacing={1}>
                <Accordion defaultIndex={[0,1,2,3,4]} allowMultiple>
                <Bulletpoint bulletpoint={bullets.bullet1} setBullets={setBullets} index={0} title="Bullet Point 1"></Bulletpoint>
                <Bulletpoint bulletpoint={bullets.bullet2} setBullets={setBullets} index={1} title="Bullet Point 2"></Bulletpoint>
                <Bulletpoint bulletpoint={bullets.bullet3} setBullets={setBullets} index={2} title="Bullet Point 3"></Bulletpoint>
                <Bulletpoint bulletpoint={bullets.bullet4} setBullets={setBullets} index={3} title="Bullet Point 4"></Bulletpoint>
                <Bulletpoint bulletpoint={bullets.bullet5} setBullets={setBullets} index={4} title="Bullet Point 5"></Bulletpoint>
                </Accordion>
            </Stack>
            </AccordionPanel>
            
            
        </Card>
        </AccordionItem>
        </Accordion>
    )
}

export default BulletsBox;